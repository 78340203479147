import { Investor, BankAccountChange, User, DataChangeRequest } from './user';
import { Payment, Investment } from './investment';
import { Asset } from './asset';
import { Project } from './project';
import { DefaultBid } from './bid';
import { Valuation } from './valuation';
import { IdentificationRequest } from './identificationRequest';
import { IdinIssuers, Idin } from './idin';
import { GenericPaymentMethods } from './checkout';
import { CreateMerchantSettlementInterval } from './opp';
import { firebase } from '@/firebase';

/**
 * Generic Bloqify settings.
 */
export interface Settings {
  bloqifyVersion: string;
  bloqadminVersion: string;
  bloqifyDevVersion: string;
  bloqadminDevVersion: string;
  updatedDateTime: string;
}

export interface LoginOrigin {
  originIsLogin: boolean;
}

export interface FundCard {
  showFundSize: boolean;
  showAvailable: boolean;
  showDividendFormat: boolean;
}
export enum DividendFormatOptions {
  divComponent = 'divcomponent',
  calculation = 'calculation',
}
export interface AvailableCard extends FundCard {
  divFormatFormat?: DividendFormatOptions;
}

export interface MyTransactions {
  showMyTransactions: boolean;
  showDividendsFormat: boolean;
  showPaymentStatus: boolean;
  showOnlineOffline: boolean;
  showWithdrawalButton: boolean;
}

export interface MarketplaceCard {
  showDividendsFormat: boolean;
  showLocation: boolean;
  showDate: boolean;
  showDocuments: boolean;
  showFundSize: boolean;
  showAvailability: boolean;
}

export interface CheckoutFlow {
  switchToUnits: boolean;
  showExpectedEarnings: boolean;
  investmentConditions: {
    en: string;
    nl: string;
  };
}

export enum AssetVisibility {
  Invested = 'invested',
  NotFull = 'not-full',
}

export interface PropertiesPage {
  hideMarketplace: boolean;
  assetVisibility: AssetVisibility[];
}

export enum ShowDividendFormatFrom {
  Calculation = 'calculation',
  Percentage = 'percentage',
}

export interface InvestedCard extends FundCard {
  showInitialInvestment: boolean;
  initialInvestmentFrom: 'initial' | 'current';
  showCurrentInvestment: boolean;
  showEarnings: boolean;
  showFundsEarnings: boolean;
  showReturnValue: boolean;
  showTotalReturn: boolean;
  showReturnPercentage: boolean;
  showCost: boolean;
  showFundsCosts: boolean;
  showRepayments: boolean;
  divInvestedFormatFormat?: DividendFormatOptions;
  showDividendFormatFrom?: 'calculation' | 'percentage';
}

export enum ReturnOptions {
  percentage = 'percentage',
  amount = 'amount',
  both = 'both',
}

export enum EarningsOptions {
  actual = 'actual',
  expected = 'expected',
}

export interface HistoryCard {
  showEarnings: boolean;
  showRepayments: boolean;
  showCosts: boolean;
  showLegalDocs: boolean;
  showPaymentStatus: boolean;
  showUnitsRepaid: boolean;
}
export interface Global {
  decimalsForNumbers: 2 | 3;
  removeDecimalsIfWholeNumbers: boolean;
  decimalsForPercentages: 2 | 3;
  removeDecimalsIfWholePercentages: boolean;
}
export interface Dashboard {
  initialAmountFrom: 'initial' | 'current';
  showMyReturn: boolean;
  showDirectReturn: boolean;
  showIndirectReturn: boolean;
  indirectReturnFormat: ReturnOptions;
  showTotalReturn: boolean;
  totalReturnFormat: ReturnOptions;
  showMyEarnings: boolean;
  earningsFormat: EarningsOptions;
  showTaxStatement: boolean;
}

export interface RegistrationSettings {
  showRegistrationButton: boolean;
}

export interface Navbar {
  addPortfolioToNavbar: boolean;
  showHomeButton: boolean;
  logoUrl?: string;
}

export interface UserVerification {
  private: boolean;
  business: boolean;
}

export interface BulletinBoard {
  createBuyConditions: {
    en: string;
    nl: string;
  };
  createSellConditions: {
    en: string;
    nl: string;
  };
  acceptBuyOfferConditions: {
    en: string;
    nl: string;
  };
  acceptSellOfferConditions: {
    en: string;
    nl: string;
  };
  negotiateOfferConditions: {
    en: string;
    nl: string;
  };
}

export interface InvestorPortalConfig {
  global: Global;
  navbar: Navbar;
  userVerification: UserVerification;
  dashboard: Dashboard;
  availableCard: AvailableCard;
  myTransactions: MyTransactions;
  marketplaceCard: MarketplaceCard;
  checkoutFlow: CheckoutFlow;
  investedCard: InvestedCard;
  historyDetailsOptions: HistoryCard;
  propertiesPage: PropertiesPage;
  registration: RegistrationSettings;
  bulletinBoard?: BulletinBoard;
}

// Client Docs
export interface ClientDocs {
  logo: string[];
}

// Client Data
export interface ClientData {
  companyName?: string;
  email?: string;
  url?: string;
  dir?: string;
  telephone?: string;
  kvk?: string;
  iban?: string;
  bic?: string;
  btw?: string;
}

export enum AlignmentOptions {
  left = 'left',
  center = 'center',
  right = 'right',
}

export enum SizeOptions {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum FontOptions {
  Default = 'Default',
  Brother1816 = 'Brother1816',
  DMsans = 'DMsans',
  Arial = 'Arial',
}

export enum StyleOptions {
  regular = 'Regular',
  italic = 'Italic',
  bold = 'Bold',
  boldItalic = 'BoldItalic',
}

// Doc Format
export interface DocFormat {
  logoAlignment: AlignmentOptions;
  logoSize: SizeOptions;
  titleAlignment: AlignmentOptions;
  titleSize: SizeOptions;
  titleStyle: StyleOptions;
  marginSize: SizeOptions;
  fontSize: SizeOptions;
  font: FontOptions;
  showEarningTaxes: boolean;
}

export interface Emails {
  from: string;
  sharesBought: {
    templateId: string;
    enabled: boolean;
  };
  verifyEmail: {
    templateId: string;
    format: string;
  };
  passwordReset: {
    templateId: string;
    format: string;
  };
  identificationRequest: {
    approved: {
      templateId: string;
    };
    rejected: {
      templateId: string;
    };
    format: string;
  };
  translations: {
    sharesBoughtInstrument: {
      subject: string;
      content: string;
    };
  };
}

export interface AdminPortalConfigs {
  createUser: { sendConfirmationEmail: boolean };
}

/**
 * Admin settings. Shuts down the application.
 */
export interface Admin {
  assetTypes: unknown;
  maintenance: boolean;
  maintenanceMessage: string;
  bloqadminMaintenace: boolean;
  availablePaymentMethods: GenericPaymentMethods[];
  investorPortalConfig: InvestorPortalConfig;
  clientData: ClientData;
  clientDocs: ClientDocs;
  docFormat: DocFormat;
  paymentLimits?: { [key in GenericPaymentMethods]: number };
  walletSettlement?: CreateMerchantSettlementInterval; // Investors wallet settlement config
  allowWalletPayment?: boolean; // Allows investing with investor wallet funds
  emails: Emails;
  baseUrl: string;
  supportEmail: string;
  adminPortalConfig: AdminPortalConfigs;
}

/**
 * Handles the mandatory Identification Rrequest needed to invest.
 */
export interface IdentificationSettings {
  requireApproval: {
    business: boolean;
    private: boolean;
  };
}

/**
 * Main basic unit in the state.
 */
export interface StateSlice<T = unknown> {
  status: string;
  payload?: T | unknown;
  error?: string;
  name?: string;
}

export const enum StateSliceStatus {
  Initial = 'initial',
  Processing = 'processing',
  Success = 'success',
  Error = 'error',
}

/**
 * State structure for an asset's files.
 */
export interface AssetDownloads {
  images?: StateSlice<[string, string][]>;
  floorPlanImages?: StateSlice<[string, string][]>;
  prospectus?: StateSlice<[string, string][]>;
  brochure?: StateSlice<[string, string][]>;
  // Keys for the documents are created dynamically based on the number of groups
}

/**
 * State structure for the user's files.
 */
export interface UserDownloads {
  avatar?: StateSlice<[string, string][]>;
}
export interface Downloads {
  assets?: Record<string, AssetDownloads>;
  investor?: UserDownloads;
}

/**
 * Vuex store's State (tree).
 */

// -- AUTH -- //

// enum with all pages of the Auth, thus string = path
export enum AuthComponents {
  Login = 'login',
  Login2FA = 'login2FA',
  AuthVerification = 'auth-verification',
  Reset = 'reset',
  Register = 'register',
}
export interface AuthModal {
  isOpen: boolean;
  type: AuthComponents | null;
  routeTo?: string | null;
}

export interface AuthState {
  name?: string;
  status: 'initial' | 'processing' | 'success' | 'error' | 'warning';
  action: string;
  error?: firebase.FirebaseError | null | string;
  emailVerified?: boolean;
  uid?: string;
  payload?: {
    name: string;
    user: firebase.User & { lastLoginAt: string | undefined; createdAt: string | undefined };
    verificationId: string | null;
  };
  metadata?: {
    creationTime: string | undefined;
    lastSignInTime: string | undefined;
  };
  email?: string | undefined;
}

export interface VerifySMSState {
  status: 'initial' | 'processing' | 'success' | 'error';
  action: string;
  error?: firebase.FirebaseError | null | string;
  payload?: {
    multiFactorAssertion: firebase.auth.PhoneMultiFactorAssertion;
    calledBy: string;
  };
}

export interface SupportState {
  status: 'initial' | 'processing' | 'success' | 'error';
  action: string;
  error?: firebase.FirebaseError | null | string;
  payload?: {
    sendEmailSuccess: firebase.functions.HttpsCallableResult;
  };
}

// -- DOWNLOADS -- //
export interface State {
  auth: AuthState | null;
  verifySMS: VerifySMSState | null;
  user: User | Investor | null;
  selectedInvestor: User | Investor | null;
  assets: Asset[];
  bid: StateSlice;
  bids: DefaultBid[];
  valuations: Valuation[];
  idin: Idin | null;
  investments: Investment[];
  payment: StateSlice;
  payments: Payment[];
  projects: Project[];
  support: SupportState | null;
  operations: StateSlice;
  idinIssuers: IdinIssuers | null;
  identificationRequest: IdentificationRequest | null;
  dataChangeRequests: DataChangeRequest[] | null;
  bankAccountChanges: BankAccountChange[];
  downloads: Downloads | null;
  settings: Settings | null;
  admin: Admin | null;
  identificationSettings: IdentificationSettings | null;
  authModal: AuthModal;
  misc: { initialTooltip: boolean };
  customLogin: { comesFromMobile: boolean };
  loginOrigin: { originIsLogin: boolean };
  [key: string]: unknown; // Allow any other properties with any type
}

/**
 * Implementing StateSlice.
 */
export class InitialStateSlice implements StateSlice {
  status = '';
  payload = null;
  error = '';
  name = '';
}

/**
 * Function that generates the store avoiding (or not) the modules.
 * @param includeModules handles the inclusion of the modules in the freshly generated state.
 */
export const generateInitialRootState = (includeModules?: boolean): Record<string, unknown> => ({
  auth: null,
  verifySMS: null,
  user: null,
  selectedInvestor: null,
  support: null,
  operations: new InitialStateSlice(),
  idinIssuers: null,
  identificationRequest: null,
  dataChangeRequests: null,
  bankAccountChanges: [],
  settings: null,
  admin: null,
  identificationSettings: null,
  misc: { initialTooltip: false },

  // Including all the modules
  ...(includeModules && {
    assets: [],
    projects: new InitialStateSlice(),
    valuations: [],
    investments: [],
    payments: [],
    bids: [],
    idin: null,
    payment: new InitialStateSlice(),
    downloads: {},
  }),
});
